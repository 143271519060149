import * as React from "react";

interface SquareProps {
    index               : [number, number];
    label               : string;     
    squareSize          : number;
    squareActiveSize    : number;
    size                : number;
    pop                 : boolean;
    selected            : boolean;
}

const Square: React.FunctionComponent<SquareProps> = ({
    index,
    label,
    squareSize: squareSize,
    squareActiveSize: squareActiveSize,
    size,
    selected,
    pop
}) => {
    const percentPerItem = 100 / size;

    return (// TODO: make the font works, make it bigger and bold and center, and relative to the size of the square
        <div
            className={ `react-pattern-board__square-wrapper${selected ? " selected" : ""}` }
            style={{
                width  : `${percentPerItem}%`,
                height : `${percentPerItem}%`,
                flex   : `1 0 ${percentPerItem - 3}%`
            }}
            data-index={ index }
        >
            <div
                className="react-pattern-board__square"
                style={{
                    width  : squareSize,
                    height : squareSize,
                }}
            >
                <p className="noto-sans-hebrew-text">{label}</p>  
                {/* <div
                    className={ `react-pattern-board__square-inner${pop ? " active" : ""}` }
                    style={{
                        minWidth   : squareSize,
                        minHeight  : squareSize,
                    }}
                /> */}
            </div>
        </div>
    );
};


export default Square;