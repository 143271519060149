import * as React            from "react";
import * as PropTypes        from "prop-types";
import { createGlobalStyle } from "styled-components";
import classnames            from "classnames";

import Square      from "./Square";
import Connectors from "./Connectors";

import { getSquares, getCollidedSquareIndex, getSquaresInTheMiddle, isAdjacent } from "../utils";
import { Square as SquareType } from "../types";

// TODO: debug and real styles
const Styles = createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Hebrew:wght@700&display=swap');
    * {
        user-select none
    }

    .react-pattern-board__pattern-wrapper {
        touch-action: none;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        position: relative;
    }
    .react-pattern-board__connector-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        pointer-events: none;
    }
    .react-pattern-board__connector {
        background: white;
        position: absolute;
        transform-origin: center left;
    }
    .react-pattern-board__square-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        background: transparent;

    }
    .react-pattern-board__square {
        cursor pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #aebfbf;
        border-radius: 30%;
        border-style: outset;
        border: 5px solid #07061c;
        //outline: 3px outset #a12828;
        outline-offset: -6px;
    }
    .react-pattern-board__square-inner {
        background: white;
        height: 90%;
        width: 90%;
    }
    p.noto-sans-hebrew-text {
        font-family: "Noto Sans Hebrew", sans-serif;
        font-optical-sizing: auto;
        font-weight: 700;
        font-style: normal;
        font-variation-settings: "wdth" 100;
          /* Default font size */
        font-size: 500%;

        /* Mobile view */
        @media (max-width: 600px) {
            font-size: 300%;
        }
      }
    .react-pattern-board__square-inner.active {
        animation: pop 300ms ease;
    }
    .react-pattern-board__pattern-wrapper.disabled,
    .react-pattern-board__pattern-wrapper.disabled .react-pattern-board__square {
        cursor: not-allowed;
    }
    .react-pattern-board__pattern-wrapper.disabled .react-pattern-board__square-inner,
    .react-pattern-board__pattern-wrapper.disabled .react-pattern-board__connector {
        background: grey;
    }

    .react-pattern-board__pattern-wrapper.success .react-pattern-board__square-inner,
    .react-pattern-board__pattern-wrapper.success .react-pattern-board__connector {
        background: #00ff00;
    }

    .react-pattern-board__pattern-wrapper.win .react-pattern-board__square-inner,
    .react-pattern-board__pattern-wrapper.win .react-pattern-board__connector {
        background: #00ffff;
    }

    .react-pattern-board__pattern-wrapper.error .react-pattern-board__square-inner,
    .react-pattern-board__pattern-wrapper.error .react-pattern-board__connector {
        background: red;
    }

    @keyframes pop {
        from { transform scale(1); }
        50% { transform scale(2); }
        to { transform scale(1); }
    }
`;

interface PatternBoardProps {
    path                     : number[];
    table                    : string[][];
    width?                   : number | string;
    size?                    : number;
    squareActiveSizePercent?        : number;
    connectorThickness?      : number;
    connectorRoundedCorners? : boolean;
    squareSizePercent?       : number;
    disabled?                : boolean;
    error?                   : boolean;
    success?                 : boolean;
    win?                     : boolean;
    allowOverlapping?        : boolean;
    allowJumping?            : boolean;
    allowDistant?            : boolean;
    style?                   : React.CSSProperties,
    className?               : string;
    noPop?                   : boolean;
    invisible?               : boolean;

    onChange(path: number[]) : void;
    onFinish()               : void;
}

const PatternBoard: React.FunctionComponent<PatternBoardProps> = ({
    width                   = "100%",
    size                    = 3,
    squareActiveSizePercent: squareActiveSizePercent         = 70,
    squareSizePercent: squareSizePercent               = 85,
    connectorThickness      = 6,
    connectorRoundedCorners = false,
    disabled                = false,
    error                   = false,
    success                 = false,
    win                     = false,
    allowOverlapping        = false,
    noPop                   = false,
    invisible               = false,
    allowJumping            = false,
    allowDistant            = false,
    className               = "",
    style                   = {},
    onChange,
    onFinish,
    path,
    table
}) => {
    const wrapperRef                                      = React.useRef<HTMLDivElement>(document.createElement("div"));
    const [height, setHeight]                             = React.useState<number>(0);
    const [squares, setSquares]                             = React.useState<SquareType[]>([]);
    const [position, setPosition]                         = React.useState<SquareType>({ x : 0, y : 0 });
    const [isMouseDown, setIsMouseDown]                   = React.useState<boolean>(false);
    const [initialMousePosition, setInitialMousePosition] = React.useState<SquareType | null>(null);
    const squareActiveSize = height / size * squareActiveSizePercent / 100;
    const squareSize = height/size * squareSizePercent / 100;

    const checkCollision = ({ x, y }: SquareType): void => {
        const { top, left } = wrapperRef.current.getBoundingClientRect();
        const mouse = { x : x - left, y : y - top }; // relative to the container as opposed to the screen
        const index = getCollidedSquareIndex(mouse, squares, squareActiveSize);
        if (~index && path[path.length - 1] !== index) {
            if (allowOverlapping || path.indexOf(index) === -1) {
                if (allowJumping || !path.length) {
                    onChange([...path, index]);
                } else {
                    if (allowDistant || isAdjacent(path[path.length - 1], index, size)) {
                        const squaresInTheMiddle = getSquaresInTheMiddle(path[path.length - 1], index, size);
                        if (allowOverlapping) onChange([...path, ...squaresInTheMiddle, index]);
                        else onChange([...path, ...squaresInTheMiddle.filter(square => path.indexOf(square) === -1), index]);
                    }
                }
            }
        }
    };

    const onResize = () => {
        const { top, left } = wrapperRef.current.getBoundingClientRect();
        setPosition({ x : left + window.scrollX, y : top + window.scrollY });
        return [top, left]
    };

    const onHold = ({ clientX, clientY }: React.MouseEvent) => {
        if (disabled) return;
        const [top, left] = onResize();  // retrieve boundingRect and force setPosition
        setInitialMousePosition({ x : clientX - left, y : clientY - top });
        setIsMouseDown(true);
        checkCollision({ x : clientX, y : clientY });
    };

    const onTouch = ({ touches }: React.TouchEvent) => {
        if (disabled) return;
        const [top, left] = onResize();  // retrieve boundingRect and force setPosition
        setInitialMousePosition({ x : touches[0].clientX - left, y : touches[0].clientY - top });
        setIsMouseDown(true);
        checkCollision({ x : touches[0].clientX, y : touches[0].clientY });
    };

    React.useEffect(() => {
        if (!isMouseDown) return;
        const onMouseMove = ({ clientX, clientY }: MouseEvent): void => checkCollision({ x : clientX, y : clientY });
        const onTouchMove = ({ touches }: TouchEvent): void => checkCollision({ x : touches[0].clientX, y : touches[0].clientY });
        wrapperRef.current.addEventListener("mousemove", onMouseMove);
        wrapperRef.current.addEventListener("touchmove", onTouchMove);
        return () => {
            wrapperRef.current.removeEventListener("mousemove", onMouseMove);
            wrapperRef.current.removeEventListener("touchmove", onTouchMove);
        };
    });

    React.useEffect(() => setHeight(wrapperRef.current.offsetWidth));
    React.useEffect(() => {
        window.addEventListener("resize", onResize);
        return () => window.removeEventListener("resize", onResize);
    }, []);

    React.useEffect(() => {
        const rafId = window.requestAnimationFrame(() => {
            setSquares(getSquares({ squareSize : squareSize, height, size }));
            onResize();
        });
        return () => window.cancelAnimationFrame(rafId);
    }, [height, size]);

    React.useEffect(() => {
        const onRelease = () => {
            setIsMouseDown(false);
            setInitialMousePosition(null);
            if (!disabled && path.length) onFinish();
        };

        window.addEventListener("mouseup", onRelease);
        window.addEventListener("touchend", onRelease);

        return () => {
            window.removeEventListener("mouseup", onRelease);
            window.removeEventListener("touchend", onRelease);
        };
    }, [disabled, onFinish, path]);

    return (
        <>
            <Styles />
            <div
                className    = { classnames([ "react-pattern-board__pattern-wrapper", { error, win, success, disabled }, className ]) }
                style        = {{ ...style, width, height }}
                onMouseDown  = { onHold }
                onTouchStart = { onTouch }
                ref          = { wrapperRef }
            >
                {
                    Array.from({ length : size ** 2 }).map((_, i) => {
                        const [x, y] = [Math.floor(i / size), i % size]
                        const label = table.length > x && table[x].length > y ? table[x][y] : "ERROR";
                        return <Square
                            key             = { i }
                            index           = {[ x, y] }
                            label           = { label }
                            size            = { size }
                            squareSize       = { squareSize }
                            squareActiveSize = { squareActiveSize }
                            pop             = { !noPop && isMouseDown && path[path.length - 1] === i }
                            selected        = { path.indexOf(i) > -1 }
                        />
                    })
                }
                {
                    !invisible && squares.length &&
                        <Connectors
                            initialMousePosition    = { initialMousePosition }
                            wrapperPosition         = { position }
                            path                    = { path }
                            squares                  = { squares }
                            squareSize         = { squareSize }
                            connectorRoundedCorners = { connectorRoundedCorners }
                            connectorThickness      = { connectorThickness }
                        />
                }

            </div>
        </>
    );
};

PatternBoard.propTypes = {
    path                    : PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
    width                   : PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    size                    : PropTypes.number,
    squareActiveSizePercent : PropTypes.number,
    connectorThickness      : PropTypes.number,
    connectorRoundedCorners : PropTypes.bool,
    squareSizePercent       : PropTypes.number,
    disabled                : PropTypes.bool,
    error                   : PropTypes.bool,
    success                 : PropTypes.bool,
    win                     : PropTypes.bool,
    allowOverlapping        : PropTypes.bool,
    allowJumping            : PropTypes.bool,
    allowDistant            : PropTypes.bool,
    style                   : PropTypes.object,
    className               : PropTypes.string,
    noPop                   : PropTypes.bool,
    invisible               : PropTypes.bool,
    onChange                : PropTypes.func.isRequired,
    onFinish                : PropTypes.func.isRequired
};


export default PatternBoard;
