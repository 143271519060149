import {useCallback, useEffect, useState} from "react";

export interface GameState {
    wordsFound: string[];
    bonusWordsFound: string[];
    mistakes: number;
    seed: number | null;
}

const STATE_VERSION = 2;

const getDate = () => new Date().toLocaleDateString("en-CA");

export const usePersistGameState = (seed: number | null) => {
    const [_mistakes, _setMistakes] = useState<number>(0);
    const [_wordsFound, _setWordsFound] = useState<string[]>([]);
    const [_bonusWordsFound, _setBonusWordsFound] = useState<string[]>([]);

    const currentGameState = {
        mistakes        : _mistakes,
        wordsFound      : _wordsFound,
        bonusWordsFound : _bonusWordsFound
    }

    const saveGameState = (gameState: Partial<Omit<GameState, "seed">>) => {
        localStorage.setItem("gameState", JSON.stringify({
            ...{...currentGameState, ...gameState},
            version : STATE_VERSION,
            date    : getDate(),
            seed    : seed
        }));
    };

    const clearGameState = () => {
        localStorage.removeItem("gameState");
    }

    const loadGameState = useCallback((): GameState => {
        const gameState = localStorage.getItem("gameState");
        const parsedGameState = gameState ? JSON.parse(gameState) : null;
        if (parsedGameState && parsedGameState.version === STATE_VERSION && parsedGameState.seed === seed) {
            console.log("Loaded game state", parsedGameState);
            return {
                wordsFound      : parsedGameState.wordsFound,
                bonusWordsFound : parsedGameState.bonusWordsFound,
                mistakes        : parsedGameState.mistakes,
                seed            : parsedGameState.seed
            };
        }
        clearGameState();
        return {
            wordsFound      : [],
            bonusWordsFound : [],
            mistakes        : 0,
            seed            : seed
        };
    }, [seed]);

    useEffect(() => {
        if (!seed) {
            return;
        }
        const gameState = loadGameState();
        _setMistakes(gameState.mistakes);
        _setWordsFound(gameState.wordsFound);
        _setBonusWordsFound(gameState.bonusWordsFound);
    }, [loadGameState, seed]);

    const setMistakes = (mistakes: number) => {
        _setMistakes(mistakes);
        saveGameState({mistakes});
    }

    const setFoundWords = (wordsFound: string[]) => {
        _setWordsFound(wordsFound);
        saveGameState({wordsFound});
    }

    const setFoundBonusWords = (bonusWordsFound: string[]) => {
        _setBonusWordsFound(bonusWordsFound);
        saveGameState({bonusWordsFound});

    }
    console.log("Current game state", currentGameState);
    return {
        clearGameState,
        mistakes        : _mistakes,
        wordsFound      : _wordsFound,
        bonusWordsFound : _bonusWordsFound,
        setMistakes,
        setFoundWords,
        setFoundBonusWords
    };
}
