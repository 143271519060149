import React from "react";
import "./Header.styl";
import {Typography} from "@mui/material";

export const Header = () => {
    return (
        <div className="header">
            <Typography fontSize={36}>סקוורדודל</Typography>
        </div>
    );
}
