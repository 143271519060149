import React, {useState, useEffect, FC} from "react";
import "./MessagePanel.styl";

interface Word {
    text: string;
    status?: "success" | "error";
}

interface MessagePanelProps {
    word: Word | null;
    message?: string;
}

const MessagePanel: FC<MessagePanelProps> = ({word, message}) => {
    const [visibleMessage, setVisibleMessage] = useState(message);

    useEffect(() => {
        if (message) {
            setVisibleMessage(message);
        }
        const timer = setTimeout(() => {
            setVisibleMessage("");
        }, 1000);
        return () => clearTimeout(timer);
    }, [message]);
    const statusEmoji = word?.status === "success" ? "✅" : word?.status === "error" ? "❌" : "";
    return (
        <div className="message-panel">
            {visibleMessage && <div className="message">{visibleMessage}</div>}
            {word && !visibleMessage && (
                <div className={`word-box ${word.status}`}>
                    <span>{word.text + "    " + statusEmoji}</span>
                </div>
            )}
        </div>
    );
};

export default MessagePanel;
