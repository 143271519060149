import React from "react";
import { IconButton, Snackbar, Typography } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import "./ShareButton.styl";

interface ShareButtonProps {
  numberOfWords: number;
  numberOfAttempts: number;
}

const getText = (numberOfWords: number, numberOfAttempts: number) => (
    `מצאתי ${numberOfWords} מילים ב${numberOfAttempts} ניחושים! 

    תרצו לנסות?    
    https://www.squardudel.space/
    `
);

const ShareButton: React.FC<ShareButtonProps> = ({ numberOfWords, numberOfAttempts }) => {
    const [open, setOpen] = React.useState(false);

    const text = getText(numberOfWords, numberOfAttempts);

    const handleWhatsAppShare = () => {
        const encodedText = encodeURIComponent(text);
        const url = `https://api.whatsapp.com/send?text=${encodedText}`;
        window.open(url, "_blank");
    };

    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(text).then(() => {
            setOpen(true);
        });
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <div className="share-button-container">
                <Typography>שיתוף</Typography>
                <div className="share-button-icons">
                    <IconButton
                        color="primary"
                        onClick={handleWhatsAppShare}
                    >
                        <WhatsAppIcon />
                    </IconButton>
                    <IconButton
                        color="primary"
                        onClick={handleCopyToClipboard}
                    >
                        <ContentCopyIcon />
                    </IconButton>
                </div>
            </div>
            <Snackbar
                open={open}
                autoHideDuration={1000}
                onClose={handleClose}
                message="הועתק בהצלחה"
            />
        </>
    );
};

export default ShareButton;
