import React, { useState, useEffect } from "react";

interface LevelData {
  size: number;
  table: string[][];
  words: string[];
}

const validateData = (data: LevelData): boolean => {
    if (!data.size || !Array.isArray(data.table) || !Array.isArray(data.words)) {
        throw new Error("Invalid data");
    }
    if (data.size !== data.table.length || data.size !== data.table[0].length) {
        throw new Error("Invalid table dimensions");
    }
    if (data.words.length === 0) {
        throw new Error("Words array is empty");
    }
    return true;
};

const LevelLoader = () => {
    const [GameComponent, setGameComponent] = useState<React.ComponentType<any> | null>(null);
    const [error, setError] = useState<string>("");

    useEffect(() => {
        const loadData = async () => {
            try {
                const data: LevelData = await import("../../../levels/Demo1.json");
                validateData(data);
                const Game = await import("../../../src/components/Game").then(module => module.default);
                setGameComponent(() => () => <Game defaultLevel={{
                    seed  : null,
                    size  : data.size,
                    table : data.table,
                    words : data.words

                }}/>);
            } catch (err: any) {
                setError(err.message);
            }
        };

        loadData();
    }, []);

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!GameComponent) {
        return <div>Loading game...</div>;
    }

    return <GameComponent />;
};

export default LevelLoader;
