import { useState, useEffect, useCallback } from "react";
import {LevelData} from "../../types";

export const useFetchLevelData = (defaultLevel: LevelData) => {
    const [levelData, setLevelData] = useState<LevelData>(defaultLevel);
    const [isFetchingLevelData, setIsFetchingLevelData] = useState(false);

    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1
    const day = String(now.getDate())

    const fetchLevelData = useCallback(async () => {
        setIsFetchingLevelData(true);

        const url = `https://raw.githubusercontent.com/oneYAD/SDLevels/main/${year}/${month}/${day}-level.json`;
        // const url = `https://raw.githubusercontent.com/oneYAD/SDLevels/main/${year}/07/7-level.json`;

        try {
            const response = await fetch(url);

            if (response.ok) {
                const data = await response.json();
                setLevelData(data);
            }
        } catch (error) {
            console.error("Error fetching level data:", error);
        } finally {
            setIsFetchingLevelData(false);
        }
    }, [day, month, year]);

    useEffect(() => {
        fetchLevelData();
    }, [fetchLevelData]);

    return { levelData, isFetchingLevelData };
};
