import React from "react";
import { LayoutProps } from "./LayoutProps";
import MessagePanel from "../MessagePanel/MessagePanel";
import PatternBoard from "../PatternBoard";
import FoundWords from "../FoundWords";
import StatisticsModal from "../StatisticsModal";
import GameOverModal from "../GameOverModal/GameOverModal";
import {Header} from "../Header/Header";
import {Typography, Button} from "@mui/material";

const MobileLayout: React.FC<LayoutProps> = ({
    size, table, words, mistakes, wordsFound, bonusWordsFound,
    isStatisticsModalOpen, setIsStatisticsModalOpen, infoMessage,
    path, setPath, currentWordStatus, isGameOverModalOpen, setIsGameOverModalOpen,
    isDone, isDisabled, attemptStatus, wordsNotFound, currentWord, onFinish
}) => {
    return (
        <React.Fragment>
            <Header />
            <MessagePanel word={{text : currentWord, status : currentWordStatus}} message={infoMessage}/>
            <div className="center">
                <PatternBoard
                    size={size}
                    onChange={(newPath) => setPath(newPath)}
                    path={path}
                    error={attemptStatus === "error"}
                    onFinish={onFinish}
                    connectorThickness={5}
                    disabled={isDisabled}
                    success={attemptStatus === "success"}
                    win={isDone}
                    table={table}
                />
            </div>
            <div className="splitted">
                <div className="split left">
                    <p>ניסיונות שגויים: {mistakes}</p>
                </div>
                <div className="split right">
                    <p>מילים שנותרו: {wordsNotFound.length}</p>
                </div>
            </div>
            <div className="output" onClick={() => setIsStatisticsModalOpen(true)} role={"button"}>
                <Typography fontSize={20}>מילים שמצאת</Typography>
                <FoundWords words={[...wordsFound, ...bonusWordsFound]}/>
            </div>
            <Button 
                variant="contained" 
                color="primary" 
                href="https://forms.gle/mYuH5oauT9aRL3iT8" 
                target="_blank" 
                rel="noopener noreferrer"
            >
                אשמח לעזור \ לדווח על באג
            </Button>
            <StatisticsModal
                open={isStatisticsModalOpen}
                handleClose={() => setIsStatisticsModalOpen(false)}
                words={words}
                foundWords={wordsFound}
                bonusWordsFound={bonusWordsFound}
            />
            <GameOverModal
                open={isGameOverModalOpen}
                handleClose={() => setIsGameOverModalOpen(false)}
                numberOfWords={wordsFound.length}
                numberOfAttempts={mistakes + wordsFound.length}
            />
        </React.Fragment>
    );
};

export default MobileLayout;
