import {Box, Modal} from "@mui/material";
import React from "react";
import {FC} from "react";

interface IStatisticsModalProps {
    open: boolean;
    handleClose: () => void;
    words: string[];
    foundWords: string[];
    bonusWordsFound?: string[];
}

const style = {
    position  : "absolute" as const,
    top       : "50%",
    left      : "50%",
    transform : "translate(-50%, -50%)",
    width     : 400,
    bgcolor   : "background.paper",
    border    : "2px solid #000",
    boxShadow : 24,
    p         : 4
};

const groupByWordLength = (words: string[]) => {
    const groupedWords: { [key: number]: string[] } = {};
    words.forEach(word => {
        if (!groupedWords[word.length]) {
            groupedWords[word.length] = [];
        }
        groupedWords[word.length].push(word);
    });
    return groupedWords;
}

const getWordsOfLength = (length: number, words: string[]) => {
    return words.filter(word => word.length === length);
}

const StatisticsModal: FC<IStatisticsModalProps> = ({open, handleClose, words, foundWords, bonusWordsFound}) => {
    const groupedWordsCount = groupByWordLength(words);
    return (
        <Modal
            open={open}
            onClose={handleClose}
        >
            <Box sx={style}>
                <div style={{textAlign : "center"}}>
                    <h2>מילים שמצאת</h2>
                    {Object.entries(groupedWordsCount).map(([length, words]) => (
                        <div key={length}>
                            <h3 style={{direction : "rtl"}}>{length} אותיות</h3>
                            <p>{getWordsOfLength(Number(length), foundWords).length} / {words.length}</p>
                        </div>
                    ))}
                    {bonusWordsFound && bonusWordsFound.length > 0 && (
                        <div>
                            <h3>מילות בונוס</h3>
                            <p>{bonusWordsFound.length} / ?</p>
                        </div>
                    )}
                </div>
            </Box>
        </Modal>
    );
}

export default StatisticsModal;
