import React from "react";
import "./FoundWords.styl";

interface WordBlocksProps {
    words: string[];
}

const FoundWords: React.FC<WordBlocksProps> = ({ words }) => {
    return (
        <div className="word-container">
            {words.map((word, index) => (
                <div key={index} className="word-block">
                    {word}
                </div>
            ))}
        </div>
    );
};

export default FoundWords;
