import {useState} from "react";

export type AttemptStatus = "success" | "error" | "neutral";

export const useBoardState = () => {
    const [isDone, setIsDone] = useState<boolean>(false);
    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const [attemptStatus, setAttemptStatus] = useState<AttemptStatus>("neutral");

    const setAttempt = (status: AttemptStatus) => {
        setAttemptStatus(status);
        setIsDisabled(true);
    }

    const clearState = () => {
        setIsDone(false);
        setIsDisabled(false);
        setAttemptStatus("neutral");
    }

    const setDone = () => {
        setIsDone(true);
        setIsDisabled(true);
    }

    return {
        isDone,
        isDisabled,
        attemptStatus,
        setAttempt,
        clearState,
        setDone
    }
}
