import React, {useCallback, useEffect, useMemo, useState} from "react";
import {AttemptStatus, useBoardState} from "./hooks/useBoardState";
import {usePersistGameState} from "./hooks/usePersistGameState";
import useMediaQuery from "@mui/material/useMediaQuery";
import {LayoutProps} from "./Layout/LayoutProps";
import MobileLayout from "./Layout/MobileLayout";
import DesktopLayout from "./Layout/DesktopLayout";
import {useFetchLevelData} from "./hooks/useFetchLevelData";
import {LevelData} from "../types";
import {CircularProgress} from "@mui/material";


const Game = ({defaultLevel}: { defaultLevel: LevelData }) => {
    const isMobile = useMediaQuery("(max-width: 600px)");

    const {
        levelData,
        isFetchingLevelData
    } = useFetchLevelData(defaultLevel);

    const {words, size, table} = levelData;
    const {
        mistakes,
        setMistakes,
        wordsFound,
        setFoundWords,
        bonusWordsFound,
        setFoundBonusWords
    } = usePersistGameState(levelData.seed);

    console.log(bonusWordsFound);


    const [isStatisticsModalOpen, setIsStatisticsModalOpen] = useState(false);
    const [infoMessage, setInfoMessage] = useState<string>();
    const [path, setPath] = useState<number[]>([]);
    const [currentWordStatus, setCurrentWordStatus] = useState<"success" | "error">();
    const [isGameOverModalOpen, setIsGameOverModalOpen] = useState(false);
    const {
        isDone,
        isDisabled,
        attemptStatus,
        setAttempt,
        clearState: clearBoardState,
        setDone
    } = useBoardState();

    const wordsNotFound = useMemo(() => words.filter(w => !wordsFound.includes(w)), [words, wordsFound]);
    const currentWord = useMemo(() => path.map(i => table[Math.floor(i / size)][i % size]).join(""), [path, size, table]);

    const setAttemptResults = useCallback(({infoMessage, attemptStatus, wordStatus}: {
        infoMessage: string,
        attemptStatus: AttemptStatus,
        wordStatus?: "success" | "error"
    }) => {
        setInfoMessage(infoMessage);
        setAttempt(attemptStatus);
        if (wordStatus) {
            setCurrentWordStatus(wordStatus);
        }
    }, [setInfoMessage, setAttempt, setCurrentWordStatus]);

    useEffect(() => {
        if (wordsNotFound.length === 0) {
            setDone();
            setIsGameOverModalOpen(true);
        }
    }, [setDone, wordsNotFound]);

    const onFinish = useCallback(() => {
        if (currentWord.length < 4) {
            setAttemptResults({infoMessage : "מילה קצרה מדי", attemptStatus : "error"});
        } else if (wordsFound.includes(currentWord) || bonusWordsFound.includes(currentWord)) {
            setAttemptResults({infoMessage : "מילה כבר נמצאה", attemptStatus : "neutral", wordStatus : "success"});
        } else if (wordsNotFound.includes(currentWord)) {
            setFoundWords([...wordsFound, currentWord]);
            setAttemptResults({
                infoMessage   : `מדהים! מצאת מילה חדשה- ${currentWord}`,
                attemptStatus : "success",
                wordStatus    : "success"
            })
        } else if (levelData.bonus_words?.includes(currentWord)) {
            setFoundBonusWords([...bonusWordsFound, currentWord]);
            setAttemptResults({
                infoMessage   : `מדהים! מצאת מילת בונוס- ${currentWord}`,
                attemptStatus : "success",
                wordStatus    : "success"
            });
        } else {
            setMistakes(mistakes + 1);
            setAttemptResults({infoMessage : "מילה לא קיימת", attemptStatus : "error", wordStatus : "error"});
        }

        setTimeout(() => {
            setPath([]);
            setInfoMessage("");
            clearBoardState();
        }, 500);
    }, [bonusWordsFound, clearBoardState, currentWord, levelData.bonus_words, mistakes, setAttemptResults,
        setFoundBonusWords, setFoundWords, setMistakes, wordsFound, wordsNotFound]);

    const commonProps: LayoutProps = {
        size, table, words,
        mistakes, wordsFound, bonusWordsFound,
        isStatisticsModalOpen, setIsStatisticsModalOpen, infoMessage,
        path, setPath, currentWordStatus, setCurrentWordStatus, isGameOverModalOpen, setIsGameOverModalOpen,
        isDone, isDisabled, attemptStatus, clearBoardState, setDone, wordsNotFound, currentWord, onFinish
    };

    if (isFetchingLevelData) {
        return <CircularProgress/>;
    }

    return isMobile ? <MobileLayout {...commonProps} /> : <DesktopLayout {...commonProps} />;

};

export default Game;
