import * as React    from "react";
import * as ReactDOM from "react-dom";
import levelFromJson from "./Components/Levels";

const render = (Component: React.ComponentType) => {
    ReactDOM.render(
        <Component />,
        document.getElementById("root")
    );
};

render(levelFromJson); // TODO: fix why not working with Demo (and then with levelDemo) with table
// while removing table to empty array it works
