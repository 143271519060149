import React from "react";
import {Modal, Box, Typography} from "@mui/material";
import ShareButton from "./children/ShareButton/ShareButton";
import "./GameOverModal.styl";

interface ShareModalProps {
  open: boolean;
  handleClose: () => void;
  numberOfWords: number;
  numberOfAttempts: number;
}

const GameOverModal: React.FC<ShareModalProps> = ({ open, handleClose, numberOfAttempts, numberOfWords }) => {
    return (
        <Modal open={open} onClose={handleClose}>
            <Box className="share-modal">
                <div className="buttons-box">
                    <Typography dir="rtl" variant='h5'>מדהים!</Typography>
                    <ShareButton numberOfAttempts={numberOfAttempts} numberOfWords={numberOfWords} />
                </div>
            </Box>
        </Modal>
    );
};

export default GameOverModal;
